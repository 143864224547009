// create a template for every frontend-facing page
// consisting of a header and a footer with <content> in between
import Head from 'next/head';
import Link from 'next/link';

import styles from '../styles/Home.module.css';

interface ScaffoldingProps {
    children: React.ReactNode;
    pageTitle?: string;
}

const Scaffolding = (props: ScaffoldingProps) => {
    return (
        <div className={styles.container}>
            <Head>
                <title>
                    txtio: simple bookmarking, omnifocus inbox push, recipe
                    parser, and feedbin share url
                </title>
                <meta
                    name='description'
                    content='Generated by create next app'
                />
            </Head>

            <div className={styles.main}>
                <header>
                    <Link href='/'>
                        <a>
                            <h1>TXTIO</h1>
                        </a>
                    </Link>
                    <p>
                        1-click bookmarklet to send things to omnifocus via mail
                        drop.
                    </p>
                </header>
                <div>{props.children}</div>
            </div>
            <footer className={styles.footer}>
                <section className={styles.disclaimer}>
                    <h5>What is this?</h5>
                    <div className={styles.aboutpre}>
                        <p>
                            Created by{' '}
                            <a href='https://fun-club.xyz/'>Fun Club</a>.
                        </p>
                        <p>
                            (It’s important to note:{' '}
                            <em>use at your own risk</em>.)
                        </p>
                    </div>
                </section>
            </footer>
        </div>
    );
};

export default Scaffolding;
