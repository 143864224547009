import type { NextPage } from 'next';
import { useState } from 'react';
import Scaffolding from '../components/txt-template';
import styles from '../styles/Home.module.css';

const Home: NextPage = () => {
    const [email, setEmail] = useState('');
    const [reminder, setReminder] = useState('');
    const [obscurl, setSuccessfulOBSCURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [pastSignup, setPastSignup] = useState(false);
    const [error, setError] = useState('');

    // user the /api/adduser endpoint to add a user to the database
    const addUser = () => {
        if (email.length === 0) {
            setReminder('Please enter an email address');
            return;
        }
        setLoading(true);
        setPastSignup(true);
        fetch('/api/adduser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                setSuccessfulOBSCURL(data.obscurl);
                setLoading(false);
            })
            .catch((err) => {
                // console.log(err);
                setLoading(false);
                setError(
                    'Something went wrong, but it’s more than likely our fault. We’ll try later!'
                );
            });
    };

    return (
        <Scaffolding>
            <p>Other things you can do here:</p>
            <ol className={styles.list}>
                <li>
                    Create a bookmarklet to send things to your OmniFocus Inbox
                    via Mail Drop.
                </li>
                <li>
                    Use a <em>super</em> simple bookmarking tool (via
                    bookmarklet and obscured URL to access ‘em) like del.icio.us
                    or pinboard, but with 1% of the functionality.
                </li>
                <li>
                    Create a simple <a href='https://feedbin.com/'>Feedbin</a>{' '}
                    <a href='https://feedbin.com/blog/2013/04/25/sharing/'>
                        Share URL
                    </a>
                    .
                </li>
                <li>Parse Serious Eats recipes straight to your Inbox.</li>
            </ol>
            {reminder.length !== 0 && (
                <div className={styles.reminder}>{reminder}</div>
            )}
            {!pastSignup && (
                <div className={styles.formHolder}>
                    <input
                        type='email'
                        name='email'
                        placeholder='joe.b@example.com'
                        className={styles.emailInput}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                        type='submit'
                        className={styles.btn}
                        onClick={addUser}
                    >
                        Sign Up
                    </button>
                </div>
            )}
            {loading && (
                <div className={styles.loading}>
                    <p>Loading...</p>
                </div>
            )}
            {pastSignup && obscurl.length === 10 && error.length < 1 ? (
                <div className={styles.success}>
                    <h2>You’re in!</h2>
                    <p>
                        Now you’ve gotta check your email to activate your
                        account.
                    </p>
                    <p>Go check that email!</p>
                </div>
            ) : (
                <div className={styles.error}>{error}</div>
            )}
        </Scaffolding>
    );
};

export default Home;
